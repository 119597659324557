<template>
  <div class="modal fade" data-backdrop="static" data-keyboard="false" id="newObjectiveEvidence" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <form class="modal-content" @submit.prevent="submit">
        <div class="modal-header">
          <div class="modal-title w-100 text-center">
            <h5 class="font-weight-bold">ADD NEW OBJECTIVE EVIDENCE </h5>
          </div>
        </div>
        <div class="modal-body">
          <!-- FOR Category  -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Category</label>
            <div class="col-sm-9">
              <select class="form-control form-control-sm my-2" v-model="oe.category_id">
                <option :value="null">-- Select Category --</option>
                <template v-for="category in companyOECategories">
                  <option :value="category.id">{{ category.name }}</option>
                </template>
              </select>
            </div>
          </div>
          <!-- FOR Ref to SMS/Regulator -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Ref to SMS/Regulator</label>
            <div class="col-sm-9">
              <input type="text" class="form-control form-control-sm" v-model="oe.reference">
            </div>
          </div>


          <!--          FOR Objective Evidence Description  -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Objective Evidence Description</label>
            <div class="col-sm-9">
              <vue-editor class="my-2" :editor-toolbar="toolbar" v-model="oe.description">
              </vue-editor>
            </div>
          </div>
          <!-- FOR Corrective Action Request -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Corrective Action Request</label>
            <div class="col-sm-9">
              <vue-editor v-model="oe.ca_request" class="my-2" :editor-toolbar="toolbar">
              </vue-editor>
            </div>
          </div>
          <!--          FOR Rectified on the spot -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Rectified on the spot</label>
            <div class="col-sm-9">
              <input class="ml-0 form-check-input checkbox-input" type="checkbox" value="" id="training_need" :checked="oe.rectified === 1" @click="oe.rectified = setOneOrZero(oe.rectified)">
            </div>
          </div>
          <!--          FOR Rectified Date -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Rectification Date</label>
            <div class="col-sm-9">
              <input type="date" :min="minimumDate"  class="form-control form-control-sm" v-model="oe.rectified_date">
            </div>
          </div>
          <!-- FOR Rectification Comment -->
          <div class="form-group row no-gutters mb-1">
            <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Rectification Comment</label>
            <div class="col-sm-9">
              <vue-editor v-model="oe.rectified_comment" class="my-2" :editor-toolbar="toolbar">
              </vue-editor>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" ref="NewUser_close" @click.prevent="closeModal">Close</button>
<!--          <button type="submit" class="btn btn-sm btn-primary" :disabled="noSelectedAuditTypeOne" :class="noSelectedAuditTypeOne ? 'e-btn-blue-disabled' : ''">Create New Record</button>-->
          <button type="submit" class="btn btn-sm btn-primary">Create New Record</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import {AlertService} from "@/services/AlertService";
import DateMixin from "@/mixins/DateMixin";
import CompanyObjectiveEvidenceMixin from "@/mixins/CompanyObjectiveEvidenceMixin";
import CompanyNonConformityMixin from "@/mixins/CompanyNonConformityMixin";
import requestLoaderMixin from "@/mixins/requestLoaderMixin";

export default {
  name : 'CompanyAddObjectiveEvidence',
  mixins : [DateMixin, CompanyObjectiveEvidenceMixin, CompanyNonConformityMixin, requestLoaderMixin],
  methods: {
    ...mapActions([
      'createCompanyObjectiveEvidence',
    ]),

    closeModal() {
      this.oe = this.setOeDetailInitialValues();
      $('#newObjectiveEvidence').modal('hide');
    },
    async submit() {
      this.showLoaderRequest('SAVING OBJECTIVE EVIDENCE, PLEASE WAIT...');

      let param = Object.assign({}, this.oe);
      param.id = this.nonConformities[this.$route.params.type][this.$attrs['active-ncr-index']].id;
      param.type = this.$route.params.type;
      const createResponse = await this.createCompanyObjectiveEvidence(param);
      if (createResponse.result === false) {
        return AlertService.errorAlert(createResponse.message, 'ADDING OF NEW OBJECTIVE EVIDENCE');
      }
      this.hideLoaderRequest();

      AlertService.successAlert('Created Successfully', 'ADDING OF NEW OBJECTIVE EVIDENCE');
      this.closeModal();

      await this.getCompanyAuditRecordById(this.$route.params.id);
      await this.getCompanyNonConformities({
        id : this.selectedCompanyAuditRecord[this.$route.params.type].id,
        type : this.$route.params.type
      });

    }
  }
}
</script>

<style scoped>

</style>
