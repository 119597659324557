<template>
  <div class="card border-0">
    <div class="row justify-content-between">
      <div class="col-auto">
        <h5 class="e-text-red">
          Objective Evidence
        </h5>
      </div>
      <div class="col-auto text-right">
        <button class="e-btn e-btn-green mb-2 px-4 e-text-white mx-2" data-toggle="modal" data-target="#newObjectiveEvidence">
          <font-awesome-icon icon="plus" class="mr-2"/>
          Add Objective Evidence
        </button>
      </div>
    </div>
    <table class="table table-sm table-bordered text-center">
      <thead>
      <tr>
        <th>O.E #</th>
        <th>Description</th>
        <th>Rectified on the spot</th>
        <th>Corrective Action Request</th>
        <th>Category</th>
        <th>Ref to SMS/Regulator</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(oe, index) in objectiveEvidences" :class="{ 'e-bg-blue-light-8' : oe.id === parseInt($route.params.oe_id) }">
        <td class="align-middle">{{ index + 1}}</td>
        <td class="align-middle">{{ oe.description | stripHtmlTags }}</td>
        <td class="align-middle">{{ oe.rectified === 1 ? 'YES' : 'NO' }}</td>
        <td class="align-middle">{{ oe.ca_request | stripHtmlTags }}</td>
        <td class="align-middle">{{ oe.category?.name }}</td>
        <td class="align-middle">{{ oe.reference }}</td>
        <td class="align-middle">
          <div class="dropdown dropright">
            <button
              id="actionDropdown"
              aria-expanded="false"
              aria-haspopup="true"
              class="mr-2 btn btn-sm btn-secondary force-white-all"
              data-toggle="dropdown"
            >
              <font-awesome-icon icon="cogs"/>
            </button>
            <div aria-labelledby="actionDropdown" class="dropdown-menu py-0" style="min-height: 50px">
              <button class="dropdown-item text-secondary font-weight-bolder"
                      @click="viewSelected(oe.id, index)">
                <font-awesome-icon icon="eye"/>
                View
              </button>
              <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                      @click="deleteSelected(oe.id)"
              >
                <font-awesome-icon icon="trash" class="force-danger-all"/>
                Delete
              </button>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <template v-if="$route.params.oe_id !== 'index' && activeOeIndex !== null">
      <company-o-e-sub-details
        :key="'oe_' + $route.params.oe_id + '_' + activeOeIndex"
        :active-oe-index="activeOeIndex"
        :active-ncr-index="$attrs['active-ncr-index']"
        :oe-length="objectiveEvidences.length"
        @changeIndex="changeIndex"
        @close="closeDetail"
      />
    </template>

    <company-add-objective-evidence
      :active-ncr-index="$attrs['active-ncr-index']"
    />
  </div>
</template>


<script>

import CompanyAddObjectiveEvidence from "@/components/modules/ia/company/nonConformity/details/CompanyAddObjectiveEvidence.vue";
import CompanyNonConformityMixin from "@/mixins/CompanyNonConformityMixin";
import CompanyOESubDetails from "@/components/modules/ia/company/nonConformity/details/ObjectiveEvidenceDetail/CompanyOESubDetails.vue";
import CompanyNonConformityDetail from "@/components/modules/ia/company/nonConformity/CompanyNonConformityDetail.vue";
import {AlertService} from "@/services/AlertService";
import {vueAppMixin} from "@/mixins/vueAppMixin";
import {mapActions} from "vuex";

export default {
  name : 'CompanyObjectiveEvidenceDetail',
  data() {
    return {
      activeOeIndex : null,
      showAddOeModal : false,
    }
  },
  mixins : [CompanyNonConformityMixin, vueAppMixin],
  components : {CompanyNonConformityDetail, CompanyAddObjectiveEvidence, CompanyOESubDetails},
  methods : {
    ...mapActions([
      'deleteCompanyObjectiveEvidence'
    ]),
    viewSelected(objectiveEvidenceId, activeOeIndex) {
      this.activeOeIndex = activeOeIndex;
      if (objectiveEvidenceId !== this.$route.params.oe_id) {
        this.$router.replace({name: 'CompanyOEMainDetails', params:
            {
              oe_id: this.companyNonConformities[this.$route.params.type][this.$attrs['active-ncr-index']]?.objective_evidences[activeOeIndex].id,
            }
        });
      }
    },
    async deleteSelected(objectiveEvidenceId) {
      if(await AlertService.confirmDeleteAlert('Delete Objective Evidence', 'Are you sure you want to delete this Objective Evidence Record?', 'Delete')){
        swal.fire({
          title:'ENTER YOUR PASSWORD',
          input: 'password',
          inputAttributes: {
            autocapitalize: 'off'
          },
          confirmButtonText:'PROCEED DELETION',
          cancelButtonText: 'CANCEL',
          showCancelButton:true,
          allowOutsideClick:false,
          allowEscapeKey:false,
          allowEnterKey:false,
          confirmButtonColor:'#dc3545',
          showLoaderOnConfirm:true,
          preConfirm:async (inputValue)=> {
            let response = await this.deleteCompanyObjectiveEvidence({id: objectiveEvidenceId, password: inputValue});
            if (typeof response === 'boolean' && response) {
              return response;
            } else {
              if (response.hasOwnProperty('message') && !response.hasOwnProperty('errors')) {
                swal.showValidationMessage(response.message);
              }
              else{
                swal.showValidationMessage(response.errors.password.join(','));
              }
            }
          }
        })
          .then(async (result)=>{
            if(result.isConfirmed){
              await this.successAlert('OBJECTIVE EVIDENCE DELETED SUCCESSFULLY','DELETE OBJECTIVE EVIDENCE RECORD');
              await this.getUpdatedNonConformity();

              if (this.$route.name !== 'CompanyObjectiveEvidenceDetail') {
                await this.$router.replace({name: 'CompanyObjectiveEvidenceDetail', params:
                    {
                      oe_id: 'index',
                    }
                });
              }
            }
          });
      }

    },
    changeIndex(newIndex) {
      this.activeOeIndex = newIndex;
      this.$router.replace({name: this.$route.name, params:
          {
            oe_id: this.companyNonConformities[this.$route.params.type][this.$attrs['active-ncr-index']]?.objective_evidences[newIndex].id,
          }
      });
    },
    closeDetail() {
      this.activeOeIndex = null;
      this.$router.replace({name: 'CompanyObjectiveEvidenceDetail', params:
          {
            oe_id: 'index',
          }
      });
    }
  },
  computed : {
    objectiveEvidences() {
      return this.companyNonConformities[this.$route.params.type][this.$attrs['active-ncr-index']]?.objective_evidences;
    },
  },
  created() {
    const oeIndex = this.companyNonConformities[this.$route.params.type][this.$attrs['active-ncr-index']]?.objective_evidences.findIndex(oe => oe.id === parseInt(this.$route.params.oe_id));
    if (oeIndex !== -1) {
      this.activeOeIndex = oeIndex;
    }
  }
}
</script>


<style scoped>

</style>
