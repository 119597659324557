<template>
  <div>
    <div class="card border-0">
      <form @submit.prevent="" class="w-100">
        <div class="row">
          <div class="col-12 text-right">
            <template v-if="toEdit">
              <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="cancel">Cancel
              </button>
              <button class="e-btn e-btn-blue ml-2" type="submit" @click="submit" :class="hasChanges === false ? 'e-btn-blue-disabled' : ''" :disabled="hasChanges === false">Save</button>
            </template>
            <button class="e-btn e-btn-green ml-2" type="button" v-else @click="editDetails">Edit Details</button>

          </div>
        </div>

        <template v-if="toEdit">
          <div class="mb-3">
            <table class="w-100">
              <tr>
                <td style="width: 25%">NCR/OBS Description</td>
                <td>
                  <vue-editor v-model="details.description" class="my-2" v-if="toEdit" :editor-toolbar="toolbar">
                  </vue-editor>
                </td>
              </tr>
              <tr>
                <td style="width: 25%">	Root Causes</td>
                <td>
                  <div class="form-group row no-gutters mb-1">
                    <div class="form-check form-check-inline">
                      <company-root-cause-selector
                        @done="setNewRootCauses"
                        :items="details.root_cause_ids"
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Training Need</td>
                <td>
                  <div class="form-group row no-gutters mb-1">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input checkbox-input" type="checkbox" value="" id="training_need" :checked="details.training_need === 1" @click="details.training_need = setOneOrZero(details.training_need)">
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="width: 25%">	Change of Procedures</td>
                <td>
                  <div class="form-group row no-gutters mb-1">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input checkbox-input" type="checkbox" value="" id="training_need" :checked="details.change_of_procedures === 1" @click="details.change_of_procedures = setOneOrZero(details.change_of_procedures)">
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="width: 25%">
                  Prevention of Recurrence ashore
                </td>
                <td>
                  <vue-editor v-model="details.recurrence_ashore" class="my-2" v-if="toEdit" :editor-toolbar="toolbar">
                  </vue-editor>
                </td>
              </tr>

              <tr>
                <td style="width: 25%">
                  Prevention of Recurrence onsite
                </td>
                <td>
                  <vue-editor v-model="details.recurrence_on_site" class="my-2" v-if="toEdit" :editor-toolbar="toolbar">
                  </vue-editor>
                </td>
              </tr>

            </table>
          </div>
        </template>
        <template v-else>
          <div class="mb-3">
            <table class="w-100">
              <tr>
                <td style="width: 25%">NCR/OBS Description</td>
                <td class="text-uppercase">
                  {{ companyNonConformities[$route.params.type][$attrs['active-ncr-index']].description | stripHtmlTags }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%" class="align-top">Root Causes</td>
                <td class="text-uppercase">
                  <template v-for="cause in groupByParentCause(companyNonConformities[$route.params.type][$attrs['active-ncr-index']].root_cause)">
                    <span class="font-weight-bold">{{ cause.name }}</span>
                    <div class="pl-3" v-for="childCauses in cause.selected_causes">
                      <font-awesome-icon
                        style="font-size: 5px; margin-bottom: 2px"
                        icon="circle"/>
                      {{ childCauses.name }}
                    </div>
                  </template>
                </td>
              </tr>
              <tr>
                <td style="width: 25%">	Training Need</td>
                <td>
                  {{ companyNonConformities[$route.params.type][$attrs['active-ncr-index']].training_need === 1 ? 'YES' : 'NO' }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">	Change of Procedures</td>
                <td>
                  {{ companyNonConformities[$route.params.type][$attrs['active-ncr-index']].change_of_procedures === 1 ? 'YES' : 'NO' }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">
                  Prevention of Recurrence ashore
                </td>
                <td>
                  {{ companyNonConformities[$route.params.type][$attrs['active-ncr-index']].recurrence_ashore | stripHtmlTags }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">
                  Prevention of Recurrence onsite
                </td>
                <td>
                  {{ companyNonConformities[$route.params.type][$attrs['active-ncr-index']].recurrence_on_site | stripHtmlTags }}
                </td>
              </tr>
            </table>
          </div>
        </template>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {AlertService} from "@/services/AlertService";
import CompanyNonConformityMixin from "@/mixins/CompanyNonConformityMixin";
import CompanyRootCauseSelector from "@/components/modules/ia/company/nonConformity/details/CompanyRootCauseSelector";
import {DataService} from "@/services/DataService";

export default {
  name : 'CompanyNcrMainDetails',
  components: {CompanyRootCauseSelector},
  mixins : [CompanyNonConformityMixin],
  data() {
    return {
      toEdit : false,
    }
  },
  methods: {
    ...mapActions([
      'updateCompanyNonConformities'
    ]),
    async editDetails() {
      await this.setDetails();
      this.toEdit = true;
    },
    async submit() {
      const updateResponse = this.updateCompanyNonConformities(this.details);
      if (updateResponse.result === false) {
        return AlertService.errorAlert(updateResponse.message, 'UPDATING OF NCR/OBS DETAIL');
      }
      await AlertService.successAlert('Updated successfully', 'UPDATING OF NCR/OBS DETAIL');
      await this.getUpdatedNonConformity();
      this.details = this.setDetailInitialValues();
      this.toEdit = false;
    },
    async cancel() {
      if (this.hasChanges === false) {
        this.details = this.setDetailInitialValues();
        this.toEdit = false;
        return;
      }
      if (await AlertService.cancelAlert()) {
        this.details = this.setDetailInitialValues();
        this.toEdit = false;
      }
    },
    async setDetails() {
      const currentDetails = this.companyNonConformities[this.$route.params.type][this.$attrs['active-ncr-index']];

      this.details = {
        id : currentDetails.id,
        description : currentDetails.description,
        training_need : currentDetails.training_need,
        change_of_procedures : currentDetails.change_of_procedures,
        recurrence_ashore : currentDetails.recurrence_ashore,
        recurrence_on_site : currentDetails.recurrence_on_site,
        root_cause_ids : currentDetails.root_cause_ids,
      }
    },
    setNewRootCauses(newValue) {
      this.details.root_cause_ids = newValue;
    }
  },
  created() {

  },
  computed : {
    hasChanges() {
      if (this.toEdit === false) {
        return false;
      }

      const originalData = {
        description : this.companyNonConformities[this.$route.params.type][this.$attrs['active-ncr-index']].description,
        root_cause_ids : this.companyNonConformities[this.$route.params.type][this.$attrs['active-ncr-index']].root_cause_ids,
        training_need : this.companyNonConformities[this.$route.params.type][this.$attrs['active-ncr-index']].training_need,
        change_of_procedures : this.companyNonConformities[this.$route.params.type][this.$attrs['active-ncr-index']].change_of_procedures,
        recurrence_ashore : this.companyNonConformities[this.$route.params.type][this.$attrs['active-ncr-index']].recurrence_ashore,
        recurrence_on_site : this.companyNonConformities[this.$route.params.type][this.$attrs['active-ncr-index']].recurrence_on_site,
      }

      const editedData = {
        description : this.details.description,
        root_cause_ids : this.details.root_cause_ids,
        training_need : this.details.training_need,
        change_of_procedures : this.details.change_of_procedures,
        recurrence_ashore : this.details.recurrence_ashore,
        recurrence_on_site : this.details.recurrence_on_site,
      }

      return DataService.checkIfChanged(originalData, editedData);
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasChanges === true) {
      if (await AlertService.cancelChanges() === true) {
        next();
      }
    } else {
      next();
    }
  }
}
</script>

<style scoped>
table td {
  padding: 2px;
}
</style>
