<template>
    <div class="card pl-2 pb-3 border-0">
      <div class="d-flex no-gutters justify-content-between">
        <div class="col-6">
          <h5 class="e-text-red">
            Objective Evidence Detail
          </h5>
        </div>
        <div></div>
        <div>
          <button class="btn btn-secondary btn-xs" :disabled="currentPage === 1" @click="$emit('changeIndex', currentPage - 2)">
            <font-awesome-icon icon="arrow-left" class="force-white-all"></font-awesome-icon>
          </button>
          {{ currentPage }}
          /
          {{ oeLength }}
          <button class="btn btn-secondary btn-xs" :disabled="currentPage === oeLength" @click="$emit('changeIndex', currentPage)">
            <font-awesome-icon icon="arrow-right" class="force-white-all"></font-awesome-icon>
          </button>


          <button class="btn btn-xs e-btn-red ml-4" @click="$emit('close')">Close</button>

        </div>
      </div>
      <nav class="navbar navbar-expand-lg p-0" id="ModelNav">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
                aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link" :to="{
                name: 'CompanyOEMainDetails'
            }">
                <span>OE Detail</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link white" :to="{
                  name: 'CompanyOEAttachments',
              }">
                <span>OE Attachments</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link white" :to="{
                  name: 'CompanyIaCompanyInstructions',
              }">
                <span>OE Company Instructions</span>
              </router-link>
            </li>
          </ul>
        </div>
      </nav>
      <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>

      <router-view
        :key="'nav-bar' + '_' + $route.params.vessel_id + '_' + $route.params.id + '_' + $route.params.type + '_' + $route.params.ncr_id + '_' + $route.params.oe_id"
        :active-ncr-index="$attrs['active-ncr-index']"
        :active-oe-index="activeOeIndex"
      ></router-view>
    </div>
</template>

<script>

import CompanyOEMainDetails from "@/components/modules/ia/company/nonConformity/details/ObjectiveEvidenceDetail/OESubComponents/CompanyOEMainDetails";
import CompanyOEAttachments from "@/components/modules/ia/company/nonConformity/details/ObjectiveEvidenceDetail/OESubComponents/CompanyOEAttachments.vue";
export default {
  name : 'CompanyOESubDetails',
  components : {CompanyOEMainDetails, CompanyOEAttachments},
  props : {
    activeOeIndex : {
      type: Number,
      default : 0
    },
    oeLength : {
      type : Number,
      default: 0
    }
  },
  data() {
    return {
      currentPage : this.activeOeIndex + 1,
      activeTab : 'OEMainDetailsTab',
    }
  }
}
</script>

<style scoped>
table td {
  padding: 2px;
}

.nav > .nav-link {
  padding: 8px 16px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  color: #435464;
  margin-bottom: -1px;
}

.nav > .active, .nav > .nav-link:hover {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;
  cursor: pointer;
}

.nav-tabs {
  border-bottom: 2px solid #dee2e6;
}

.router-link-active {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;

  * {
    color: #d3313c;
  }
}
</style>
